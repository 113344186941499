<template>
  <Navbar/>
  <div style='width: 100%;height: 100%;position: relative;top: 60px;min-width: 1280px' class='home_content_div'>
    <div class='home_top_div'>
      <div class='top_img_div'><img class='div1-lRImg1' :src='topImg2'></div>
      <div class='top_img_div'><img class='div1-lRImg2' :src='topImg1'> <img class='div1-lRImg3' :src='topImg3'></div>
      <div><img class='div1-lRImg4' :src='topImg4'></div>
    </div>
    <div class='home_top_div2'>
      <div class='top_img_div2in1'><img :src='topImg2in1' class='div2-lRImg1'></div>
      <div class='top_img_div2 div2-lRDiv1'><img class='div2-lRImg' :src='topImg2in2'></div>
      <div class='top_img_div2 div2-lRDiv2'><img class='div2-lRImg' :src='topImg2in3'></div>
      <div class='top_img_div2 div2-lRDiv3'><img class='div2-lRImg' :src='topImg2in4'></div>
      <div class='top_img_div2 div2-lRDiv4'><img class='div2-lRImg' :src='topImg2in5'></div>
    </div>
    <div class='home_top_div3'>
      <div class='top_img_div3'><img :src='topImg3in2' class='div3-lRImg1'></div>
      <div class='top_img_div3'>
        <img :src='topImg3in4' class='div3-lRImg2'>
        <img :src='topImg3in1' class='div3-lRImg3'>
      </div>
      <div class='top_img_div3'><img class='div3-lRImg1' :src='topImg3in3'></div>
    </div>
    <div class='home_top_div4'>
      <div class='home-top-padding'>
        <div class='top_img_div4'><img class='div4-lRImg1' :src='topImg4in1'></div>
        <div class='top_img_div4'><img class='div4-lRImg1' :src='topImg4in2'></div>
      </div>
      <div>
        <div class='top_img_div4'><img class='div4-lRImg2' :src='topImg4in3'></div>
        <div class='top_img_div4'><img class='div4-lRImg2' :src='topImg4in4'></div>
        <div class='top_img_div4'><img class='div4-lRImg2' :src='topImg4in5'></div>
      </div>
    </div>
    <div class='home_top_div4-1'>
      <div class='top_img_div4-1'><img class='div4-lRImg3' :src='topImg4in6'></div>
    </div>
    <div class='home_top_div5'>
      <div style='padding-top: 60px'>
        <div class='home_img_div5 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false">
<!--          <img class='div5-lRImg1' :src='topImg5in1'>-->
          <div class='div5-lRImg1'>
            <img class='div5-lRImg1-icon' :src='topImg5in1'>
           <div class='div5-flex'>
             <div class='div5-line'></div>
             <div class='div5-title-cla'>团队评估</div>
             <div class='div5-line'></div>
           </div>
            <div class='div5-content'>
              <p>团队评估：旨在帮助管理者从宏观视角洞察组织的人才结构和系统性风险，了解员工在沟通、协作、价值观等方面的整体面貌和个性差异，从而为团队组建、企业文化建设、人才选拔、人才发展提供决策参考。</p>
            </div>
          </div>
        </div>
        <div class='home_img_div5 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false">
<!--          <img class='div5-lRImg1' :src='topImg5in2'>-->
          <div class='div5-lRImg1'>
            <img class='div5-lRImg1-icon' :src='topImg5in2'>
            <div class='div5-flex'>
              <div class='div5-line'></div>
              <div class='div5-title-cla'>团队冲突</div>
              <div class='div5-line'></div>
            </div>
            <div class='div5-content'>
              <p>团队冲突模型：旨在评估团队成员在冲突情况下的个体行为偏好，从而帮助管理者洞察和管理团队冲突的风险，并通过实践反馈更新应对冲突的“策略库”，从而掌握基于员工个体差异的管理策略。</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class='home_img_div5 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false">
<!--          <img class='div5-lRImg1' :src='topImg5in3'>-->
          <div class='div5-lRImg1'>
            <img class='div5-lRImg1-icon' :src='topImg5in3'>
            <div class='div5-flex'>
              <div class='div5-line'></div>
              <div class='div5-title-cla'>团队管理</div>
              <div class='div5-line'></div>
            </div>
            <div class='div5-content'>
              <p>团队管理：提供多种员工管理工具，帮助管理者了解员工在沟通、协作、冲突模式上的具体差异；并基于员工特点提供个性化的培训发展、人才保留和激励策略，帮助管理者更有效地管理员工。</p>
            </div>
          </div>
        </div>
        <div class='home_img_div5 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false">
<!--          <img class='div5-lRImg1' :src='topImg5in4'>-->
            <div class='div5-lRImg1'>
              <img class='div5-lRImg1-icon' :src='topImg5in4'>
              <div class='div5-flex'>
                <div class='div5-line'></div>
                <div class='div5-title-cla'>创新加速</div>
                <div class='div5-line'></div>
              </div>
              <div class='div5-content'>
                <p>创新加速服务：通过测评、咨询和培训，帮助核心研发团队成员突破思维方式的盲区，加深彼此在沟通与协作上的理解，发挥各自的特长，降低组织内耗，优化研发的效率和效果。</p>
              </div>
            </div>
        </div>
        <div class='home_img_div5 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false">
<!--          <img class='div5-lRImg1' :src='topImg5in5'>-->
          <div class='div5-lRImg1'>
            <img class='div5-lRImg1-icon' :src='topImg5in5'>
            <div class='div5-flex'>
              <div class='div5-line'></div>
              <div class='div5-title-cla'>雇主品牌</div>
              <div class='div5-line'></div>
            </div>
            <div class='div5-content'>
              <p>EVP评估是一种高效的人力资源管理工具，可以对企业雇主品牌进行“定期体检”，帮助企业把有限的资源投入到吸引和留住人才的关键因素中，构建区别于竞争品牌的雇主价值主张。</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class='home_img_div5'><img class='div5-lRImg2' :src='topImg5in6'></div>
      </div>
      <div class='line-div'>
        <div></div><span>认知自我</span>
        <img class='div5-lRImg3' :src='topImg5in7'><span>选择未来</span>
        <div></div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import Navbar from '@/layout/components/Navbar'
import Bottom from '@/layout/components/Bottom'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const store = useStore()
const name = ref(store.getters.userInfo.name)

// const isMoShow = computed(() => (state2) => state2.test.showShowPcMo)
// localStorage.setItem('yq_client_token', 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjUwOCwicm9sZUlkcyI6W10sImNsaWVudElwIjoiMTkyLjE2OC4wLjEwNyIsImFwcEtleSI6IndlaWxhaSIsInRva2VuIjpudWxsLCJleHAiOjE3MTA5MzExNTF9.dFZYyUnbOQlSZ9c97OmwOfwn8XLfx8VZd7hQUosOia8')
// localStorage.setItem('timeStamp', '1710844751873')
const logout = () => {
  store.dispatch('user/logout')
}
const topImg1 = require('../../assets/images/home/1-1.png')
const topImg2 = require('../../assets/images/home/1-2.png')
const topImg3 = require('../../assets/images/home/1-3.png')
const topImg4 = require('../../assets/images/home/1-4.png')

const topImg2in1 = require('../../assets/images/home/2-1.png')
const topImg2in2 = require('../../assets/images/home/2-2.png')
const topImg2in3 = require('../../assets/images/home/2-3.png')
const topImg2in4 = require('../../assets/images/home/2-4.png')
const topImg2in5 = require('../../assets/images/home/2-5.png')

const topImg3in1 = require('../../assets/images/home/3-1.png')
const topImg3in2 = require('../../assets/images/home/3-2.png')
const topImg3in3 = require('../../assets/images/home/3-3.png')
const topImg3in4 = require('../../assets/images/home/3-4.png')

const topImg4in1 = require('../../assets/images/home/4-1.png')
const topImg4in2 = require('../../assets/images/home/4-2.png')
const topImg4in3 = require('../../assets/images/home/4-3.png')
const topImg4in4 = require('../../assets/images/home/4-4.png')
const topImg4in5 = require('../../assets/images/home/4-5.png')
const topImg4in6 = require('../../assets/images/home/4-6.png')

const topImg5in1 = require('../../assets/images/home/5-1.png')
const topImg5in2 = require('../../assets/images/home/5-2.png')
const topImg5in3 = require('../../assets/images/home/5-3.png')
const topImg5in4 = require('../../assets/images/home/5-4.png')
const topImg5in5 = require('../../assets/images/home/5-5.png')
const topImg5in6 = require('../../assets/images/home/5-6.png')
const topImg5in7 = require('../../assets/images/home/5-7.png')
const hovering = ref(false)

const isUserInfo = ref(false)
if (name.value !== null && name.value !== undefined && name.value !== '') {
  isUserInfo.value = true
}
</script>

<style lang="scss" scoped>
.navbarHome {
  height: $navbarHeight;
  overflow: hidden;
  position: fixed;
  width: 100%;
  background: $background-color-primary;
  //box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  border-bottom: $border-base;
  text-align: center;
  z-index: 1000;
  .newbtn{
    margin-top:9px;
    margin-left:25px;
  }
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    align-items: center;
    display: inline-block;
    padding-right: 16px;
    position: fixed;
    /* top: -160px; */
    /* left: 300px; */
    right: 0;
    top: 0;

    :deep(.right-menu-item) {
      display: inline-block;
      padding: 0 5px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;
        line-height: 60px;

        //&:hover {
        //  background: rgba(0, 0, 0, 0.025);
        //}
      }
    }

    :deep(.avatar-container) {
      cursor: pointer;
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
      }
    }
  }

  .avatar-container{
    line-height:$navbarHeight;

    .avatar-wrapper {
      margin-top:0px !important;
    }
  }
}
//.line-div{
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  div {
//    width: 260px;
//    border-top: 1px solid white;
//  }
//}
//.line-div span{
//  color: white;
//  font-size: 25px;
//  margin: 0 28px;
//  font-weight: bold;
//}

</style>
